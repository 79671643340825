import React from 'react'
import { Link } from 'gatsby'
import Background from '../components/Background'

import '../styles/global.css'
import '../styles/fonts.css'

import SEO from "../components/seo"

const IndexPage = () => (
    <Link to="/home">
      <Background enter>
        <SEO title="ART-SYNERGIA" />
      </Background>
    </Link>
)

export default IndexPage
